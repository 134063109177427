













































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import ConfirmDialogue from "@/vue/components/ConfirmDialogue.vue";
import ChangePasswordDialogue from "@/vue/components/ChangePasswordDialogue.vue";
import store from "@/store/store";
import UserDialogue from "@/vue/components/UserDialogue.vue";
import utils from "@/utilities/Utils";
import { UserSearchParameters } from "@/model/UserSearchParameters";
import { ILookupItem } from "@/model/LookupItem";
import { StateChanger } from "vue-infinite-loading";
import { User, IUser } from "@/model/User";
import { UserRole } from "@/model/Enums";
import * as toastr from "toastr";

@Component({
    components: { 
        ApiButton, 
        ConfirmDialogue, 
        ChangePasswordDialogue, 
        UserDialogue 
    }
})

export default class Users extends Vue {

    //
    // -- properties
    //

    private userHeaders = [
        { text: "Name", value: "name", sortable: false },
        { text: "Email", value: "email", sortable: false },
        { text: "Role", value: "role", sortable: false },
        { text: "Last Sign-In", value: "lastLoggedIn", sortable: false },
        { text: "Password Reset", value: "password", sortable: false }
    ]

    private infiniteId: number = + new Date();
    private searchParameters = new UserSearchParameters();
    private userList: Array<IUser> = [];
    private readonly user = new User();

    //
    // -- computed properties
    //

    private get roleList(): Array<ILookupItem> { return utils.enumToLookups(UserRole); }

    //
    // -- watchers
    //

    private debouncedRefreshSearch = utils.debounce(this.refreshSearch, 200);

    //
    // -- methods
    //

    private canSendReminder(user: User): boolean {
        return !user.isDeleted;
    }

    private canChangePassword(user: User): boolean {
        return !user.isDeleted;
    }

    private editNew() {
        const dialog: UserDialogue = this.$refs.userDialogue as UserDialogue;
        dialog.add();
    }
    
    private edit(user: IUser) {
        const dialog: UserDialogue = this.$refs.userDialogue as UserDialogue;
        dialog.edit(user.id);
    }

    private refreshSearch() {
        this.userList = [];
        this.searchParameters.pageNumber = 1;
        this.infiniteId += 1;
    }

    async infiniteLoadingHandler(stateChanger: StateChanger) {
        
        if (store.state.signedInUser?.id != undefined) {
            this.searchParameters.landlordID = store.state.signedInUser?.landlordID;
        }

        const response = await apiClient.post("/Api/User/Search", this.searchParameters);
        if (response.list.length) {
            // Use map to convert IUser data objects into actual instances of User class
            // (three dots is spread operator for concatenating arrays)
            this.userList.push(...response.list.map((u: IUser) => new User(u)));
            this.searchParameters.pageNumber += 1;
            stateChanger.loaded();
        }
        else {
            stateChanger.complete();
        }
    }
    
    private changePassword(user: IUser) {
        const dialogue = this.$refs.changePasswordDialogue as ChangePasswordDialogue;
        dialogue.changePassword(user);
    }

    private sendReminderCheck(user: IUser) {
        this.user.update(user);
        const dialogue = this.$refs.sendLinkDialogue as ConfirmDialogue;
        dialogue.show();
    }

    async sendReminder() {
        const request = { email: this.user.email }
        const response = await apiClient.post("/Api/Authentication/forgot", request) as { isSuccess: boolean; message: string };
        if (response.isSuccess) {
            toastr.success(this.user.forename, "Sent");
            (this.$refs.sendLinkDialogue as ConfirmDialogue).hide();
        }
        else {
            toastr.error(response.message); // could possibly use message box rather than toast?
        }
    }

}
