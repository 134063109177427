var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',[_c('div',{staticClass:"text-h4"},[_vm._v("Users")]),_c('v-spacer'),_c('api-button',{staticClass:"mb-2",on:{"click":_vm.editNew}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Add new")],1)],1),_c('v-data-table',{attrs:{"headers":_vm.userHeaders,"items":_vm.userList,"disable-pagination":"","hide-default-footer":""},on:{"click:row":_vm.edit},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.forename)+" "+_vm._s(item.surname))]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("lookup")(item.role,_vm.roleList)))]}},{key:"item.lastLoggedIn",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("whenText")(item.lastLoggedIn)))]}},{key:"item.password",fn:function(ref){
var item = ref.item;
return [(_vm.canChangePassword(item))?_c('a',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changePassword(item)}}},[_vm._v("Change")]):_vm._e(),(_vm.canChangePassword(item) && _vm.canSendReminder(item))?_c('span',[_vm._v(" / ")]):_vm._e(),(_vm.canSendReminder(item))?_c('a',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.sendReminderCheck(item)}}},[_vm._v("Send a Link")]):_vm._e()]}}],null,true)}),_c('infinite-loading',{attrs:{"identifier":_vm.infiniteId},on:{"infinite":_vm.infiniteLoadingHandler}}),_c('user-dialogue',{ref:"userDialogue",on:{"saved":_vm.refreshSearch}}),_c('change-password-dialogue',{ref:"changePasswordDialogue",attrs:{"shouldShowExisting":false},on:{"saved":_vm.refreshSearch}}),_c('confirm-dialogue',{ref:"sendLinkDialogue",attrs:{"title":"Send Link?","text":("Are your sure you want to send a link to " + (_vm.user.fullname) + "?"),"yesButtonText":"Yes, Send","yesButtonIcon":"mdi-send","noButtonText":"No, Cancel"},on:{"confirm":function($event){return _vm.sendReminder()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }